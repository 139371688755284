import React from 'react'
import { Button } from './Button'

import mobileImage from '../Images/rs_main_mobile.jpg'

export const Homescreen = (props) => {

	const handleClick = () => {
		props.handleState(0)
	}
	return (
		<div className="rs__homescreen">
			<div className="rs__homescreen_content">
				<h2>Brauche ich B.I.G. VISION®?</h2>
				<p>Mit B.I.G. VISION® bietet Rodenstock die präzisesten Brillengläser der Welt: biometrische Gleitsichtgläser. Sie sind noch kein B.I.G. VISION® Kunde? Dann können Sie mit unserem kurzen Fragebogen herausfinden, ob und wie Sie und Ihr Geschäft davon profitieren können.</p>
				<Button title='Weiter' click={handleClick} />
			</div>
			<img src={ mobileImage } alt="Dialog Keyvisual"/>
		</div>
	)
}
