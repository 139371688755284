import React, { useState } from 'react';
import GlobalStyles from './Components/Styles/GlobalStyles'
import './App.css'
import { Homescreen } from './Components/Homescreen';

import {data} from './data'
import { Question } from './Components/Question';
import Result from './Components/Result';

const language = document.getElementById('rs_dialogtool').dataset.language

function App() {

	const [step, setStep] = useState('')
	const [points, setPoints] = useState(0)


	return (
		<>
			<GlobalStyles />
			
			<div className="rodenstock__dialogtool">

				{step === "" ?
					<Homescreen handleState={setStep} />
				:
					step < data.questions.length ? <Question
						frage={data.questions[step].frage}
						antworten={data.questions[step].antworten}
						handleStep={setStep}
						step={step}
						handlePoints={setPoints}
						points={points}
					/> : <Result
							points={points}
							results={data.results}
							language={language}
						/>
				}
					
				

			
			</div>
		</>
    );
}

export default App;
