export const data = {
	questions: [
		{
			frage: "Bemerken Sie, dass der Wettbewerb durch andere Optiker (Ketten und Unabhängige) deutlich zunimmt?",
			antworten: [
				{
					antwort: 'Ja',
					punkte: 5
				},
				{
					antwort: 'Nein',
					punkte: 1
				},
				{
					antwort: 'Weiß nicht',
					punkte: 3
				}
			]
		},
		{
			frage: "Wirbt Ihr Wettbewerb verstärkt mit Premium Gleitsicht?",
			antworten: [
				{
					antwort: 'Ja',
					punkte: 5
				},
				{
					antwort: 'Nein',
					punkte: 1
				},
				{
					antwort: 'Weiß nicht',
					punkte: 3
				}
			]
		},
		{
			frage: "Können Sie sich durch besonders guten Service vom Wettbewerb abheben?",
			antworten: [
				{
					antwort: 'Ja',
					punkte: 1
				},
				{
					antwort: 'Nein',
					punkte: 5
				},
				{
					antwort: 'Weiß nicht',
					punkte: 3
				}
			]
		},
		{
			frage: "Können Sie Ihren Kunden Produkte und Services anbieten, die es so nur bei Ihnen gibt?",
			antworten: [
				{
					antwort: 'Ja',
					punkte: 1
				},
				{
					antwort: 'Nein',
					punkte: 5
				},
				{
					antwort: 'Weiß nicht',
					punkte: 3
				}
			]
		},
		{
			frage: "Können Sie bereits bei der Augenvermessung ein einzigartiges Erlebnis bieten?",
			antworten: [
				{
					antwort: 'Ja',
					punkte: 1
				},
				{
					antwort: 'Nein',
					punkte: 5
				},
				{
					antwort: 'Weiß nicht',
					punkte: 3
				}
			]
		},
		{
			frage: "Begeistern Sie Kunden in Ihrem Verkaufsraum mit faszinierender Technik?",
			antworten: [
				{
					antwort: 'Ja',
					punkte: 1
				},
				{
					antwort: 'Nein',
					punkte: 5
				},
				{
					antwort: 'Weiß nicht',
					punkte: 3
				}
			]
		},
		{
			frage: "Sind Ihre Kunden heute deutlich informierter und anspruchsvoller als früher?",
			antworten: [
				{
					antwort: 'Ja',
					punkte: 5
				},
				{
					antwort: 'Nein',
					punkte: 1
				},
				{
					antwort: 'Weiß nicht',
					punkte: 3
				}
			]
		},
		{
			frage: "Sind Ihre Kunden heute bereit, für qualitativ hochwertigere Brillengläser und bestes Sehen mehr Geld auszugeben?",
			antworten: [
				{
					antwort: 'Ja',
					punkte: 5
				},
				{
					antwort: 'Nein',
					punkte: 1
				},
				{
					antwort: 'Weiß nicht',
					punkte: 3
				}
			]
		},
		{
			frage: "Schöpfen Sie im Segment Premium-Gleitsicht Ihr Potenzial schon voll aus?",
			antworten: [
				{
					antwort: 'Ja',
					punkte: 1
				},
				{
					antwort: 'Nein',
					punkte: 5
				},
				{
					antwort: 'Weiß nicht',
					punkte: 3
				}
			]
		},
		{
			frage: "Schalten Sie mehrmals im Jahr umfangreiche Werbekampagnen (klassisch und digital), die erfolgreich viele Neukunden gewinnen bzw. Bestandskunden reaktivieren?",
			antworten: [
				{
					antwort: 'Ja',
					punkte: 1
				},
				{
					antwort: 'Nein',
					punkte: 5
				},
				{
					antwort: 'Weiß nicht',
					punkte: 3
				}
			]
		},
	],
	results: [

		{
			punkte: 10,
			overline: "Ihr Ergebnis",
			title: "B.I.G. Vision® kann für Sie die Zukunft sein",
			text: "Sie sind momentan bestens aufgestellt. Allerdings ist der Markt stark im Wandel: Stärkere Abgrenzung wird in Zukunft auch für Sie notwendig werden. Ein einzigartiges Angebot wie B.I.G. Vision® kann dann den entscheidenden Unterschied machen. Wir beraten Sie gerne.",
			buttonText: 'Jetzt unverbindlich informieren',
			buttonLink: 'bigprecision-kontakt.html',
		},

		{
			punkte: 17,
			overline: "Ihr Ergebnis",
			title: "B.I.G. Vision® kann Ihren Erfolg noch steigern",
			text: "Sie sind solide aufgestellt, könnten aber durchaus die ein oder andere Lücke in Ihrem Angebot schließen. Nutzen Sie mit B.I.G. Vision® enorme Wachstums-Chancen und setzen Sie sich mit einem einzigartigen Angebot nachhaltig vom Wettbewerb ab. Wir beraten Sie gerne.",
			buttonText: 'Jetzt unverbindlich informieren',
			buttonLink: 'bigprecision-kontakt.html',
		},

		{
			punkte: 34,
			overline: "Ihr Ergebnis",
			title: "B.I.G. Vision® ist, was Sie jetzt brauchen",
			text: "Die Konkurrenz wird immer stärker, die Kunden immer anspruchsvoller – aber Ihnen fehlen restlos überzeugende Verkaufsargumente? B.I.G. Vision® ist Ihre Chance: Differenzieren Sie sich erfolgreich, begeistern Sie neue wie alte Kunden und bringen Sie Ihr Geschäft auf Wachstumskurs. Wir beraten Sie gerne.",
			buttonText: 'Jetzt unverbindlich informieren',
			buttonLink: 'bigprecision-kontakt.html',
		}
		
	]
}