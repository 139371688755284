import React from 'react'
import { Button } from './Button'

const Result = (props) => {

	const getResults = () => {

		let results = {
			overline: "",
			title: "",
			text: "",
			buttonText: "",
			buttonLink: "",
		}
		props.results.map(result => {
			if (props.points >= result.punkte) {
				results.overline = result.overline
				results.title = result.title
				results.text = result.text
				results.buttonText = result.buttonText
				results.buttonLink = result.buttonLink
			}
			return results
		})

		return results
	}

	const handleClick = link => {
		
		let url = ""
		switch (props.language) {
			case 'de':
				url = `https://www.rodenstock.de/de/de/${link}`
				break;
			case 'at':
				url = `https://www.rodenstock.at/at/de/${link}`
				break;
			case 'ch':
				url = `https://www.rodenstock.ch/ch/de/${link}`
				break;
			default:
				url = `https://www.rodenstock.de/de/de/${link}`
		}
		return url
	}

	const results = getResults()

	return (
		<div className="rs__mainscreen">
			<div className="rs__homescreen_content">
				<p className="rs__question_header">{results.overline}<br />{results.title}</p>
				<p>{results.text}</p>
				<Button
					title={results.buttonText}
					type='link'
					link={handleClick(results.buttonLink)}
					/>
			</div>
		</div>
	)
}

export default Result
