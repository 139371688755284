import React from 'react'

export const Button = (props) => {

	return (
	<>
		{
			props.type === 'link' ?				
				<a className="rs__btn" target="_blank" rel="noopener noreferrer" href={props.link}>
					{props.title}
				</a>
				:
				<button className="rs__btn" onClick={props.click}>
					{props.title}
				</button>
			}
	</>
	)
}
