import { createGlobalStyle } from 'styled-components'
import { vars } from './StyleVariables';

import keyVisualDesktop from '../../Images/rs_main_desktop.jpg'
import keyVisualMobile from '../../Images/rs_main_mobile.jpg'

const GlobalStyle = createGlobalStyle`

.rodenstock__dialogtool {

	width: 100%;
	margin: 0;
	padding: 0;
	font-family: 'TradeGothic';
	text-rendering: optimizeLegibility !important;

	
	
	* {
		font-family: 'TradeGothic', sans-serif;
		box-sizing: border-box;
		font-family: inherit;
		user-select: none;
		font-size: ${vars.baseFontSize}px;
		line-height: 1.4;
		color: white;
		text-rendering: optimizeLegibility !important;
	}

	h1, h2, h3 {
		font-family: 'TradeGothicCond';
		/* text-transform: uppercase; */
	}

	h2 {
		font-size: ${vars.baseFontSize * 4.5}px;
		line-height: 1.2;
		margin-bottom: ${vars.baseFontSize * 2}px;
		font-weight: normal;
	}

    p, li, a, button, strong {
        font-size: ${vars.baseFontSize * 1.6}px;
    }

	p {
		line-height: 1.5;
	}

	.rs__homescreen {
		/* min-height: ${vars.baseFontSize * 30}px; */
		/* background-image: url(${keyVisualMobile}); */
		background-color: ${vars.primary};
		background-size: cover;
		background-repeat: no-repeat;
		background-position: bottom center;
		@media (min-width: ${vars.mobile}px){
			padding: ${vars.baseFontSize * 5}px ${vars.baseFontSize * 2}px;
			padding-bottom: ${vars.baseFontSize * 7}px;
			display: flex;
			align-items: center;
			background-size: auto 100%;
			background-position: right -35vw center;
			background-image: url(${keyVisualDesktop});
		}
		@media (min-width: ${vars.tablet}px){
			background-position: right -20vw center;
		}
		@media (min-width: ${vars.desktop}px){
			min-height: ${vars.baseFontSize * 35}px;
			padding: ${vars.baseFontSize * 4}px ${vars.baseFontSize * 2}px;
			display: flex;
			align-items: center;
			background-position: right center;
			background-image: url(${keyVisualDesktop});
		}
		.rs__homescreen_content {
			padding: ${vars.baseFontSize * 2}px;
			width: 100%;
			max-width: ${vars.max}px;
			margin: 0 auto;
			* {
				position: relative;
				z-index: 1;
			}
			@media (min-width: ${vars.mobile}px){
				padding: 0 40% 0 0;
			}
			p {
				max-width: ${vars.baseFontSize * 55}px;
			}
		}
		img {
			display: block;
			width: 100%;
			margin-top: -${vars.baseFontSize * 5}px;
			position: relative;
			z-index: 0;
			@media (min-width: ${vars.mobile}px){
				display: none;
		}
	}
}
	.rs__mainscreen {
		background: ${vars.primary};
		padding: ${vars.baseFontSize * 4}px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		min-height: ${vars.baseFontSize * 35}px;
		p {
				max-width: ${vars.baseFontSize * 55}px;
			}
		.rs__question_header {
			font-family: 'TradeGothicCond';
			line-height: 1.2;
			font-size: ${vars.baseFontSize * 3}px;
			padding: 0;
			margin: 0 auto ${vars.baseFontSize * 2}px;
			width: 100%;
			max-width: ${vars.baseFontSize * 55}px;
		}
		.rs__btn {
			@media (min-width: ${vars.desktop}px){
				min-width: 260px;
				margin: ${vars.baseFontSize * 4}px ${vars.baseFontSize * 2}px 0
			}
		}
	}
	
	.rs__body_content {
		background: ${vars.primary};
		background-repeat: no-repeat;
		background-position: right bottom;
		padding: ${vars.baseFontSize * 1.61}px;
		min-height: ${vars.baseFontSize * 20}px;
		display: flex;
	}

	.rs__btn {
		border: none;
		outline: none;
		font-size: ${vars.baseFontSize * 1.8}px;
		background: ${vars.secondary};
		font-family: 'TradeGothicCond', sans-serif;
		cursor: pointer;
		display: inline-block;
		padding: 15px 12px 16px;
		text-align: center;
		text-transform: uppercase;
		margin-top: ${vars.baseFontSize * 3}px;
		transition: background-color .25s ease 0s, border-color .25s ease 0s, color .25s ease 0s, transform .4s, box-shadow .4s;
		width: 100%;
		text-decoration: none;
		@media (min-width: ${vars.desktop}px){
			width: auto;
			min-width: 300px;
		}
		&:hover {
			transform: translate3d(0, -3px, 0);
			box-shadow: 0 11px 30px -20px rgba(2,16,32,0.4);

		}
	}

}

`

export default GlobalStyle;