import React from 'react'
import { Button } from './Button'

export const Question = (props) => {

	console.log(props)

	const nextQuestion = (p) => {
		props.handlePoints(props.points + p)
		props.handleStep(props.step + 1)
	}

	return (
		<div className="rs__mainscreen">
			<div className="rs__homescreen_content">
				<p className="rs__question_header">{props.frage}</p>
				{props.antworten.map((antwort, i) => (
					<Button
						key={i}
						title={antwort.antwort}
						click={() => nextQuestion( antwort.punkte )}
					/>
				))
				}
			</div>
		</div>
	)
}
